import BetterMap from "../utils/BetterMap";
import aeronImage from "../../public/images/house-cards/Aeron.png";
import alesterImage from "../../public/images/house-cards/Alester.png";
import aeroImage from "../../public/images/house-cards/Areo.png";
import arianneImage from "../../public/images/house-cards/Arianne.png";
import ashaImage from "../../public/images/house-cards/Asha.png";
import balonImage from "../../public/images/house-cards/Balon.png";
import blackfishImage from "../../public/images/house-cards/Blackfish.png";
import brienneImage from "../../public/images/house-cards/Brienne.png";
import catelynImage from "../../public/images/house-cards/Catelyn.png";
import cerseiImage from "../../public/images/house-cards/Cersei.png";
import dagmarImage from "../../public/images/house-cards/Dagmar.png";
import darkStarImage from "../../public/images/house-cards/DarkStar.png";
import davosImage from "../../public/images/house-cards/Davos.png";
import doranImage from "../../public/images/house-cards/Doran.png";
import eddardImage from "../../public/images/house-cards/Eddard.png";
import euronImage from "../../public/images/house-cards/Euron.png";
import garlanImage from "../../public/images/house-cards/Garlan.png";
import greatjonImage from "../../public/images/house-cards/Greatjon.png";
import gregorImage from "../../public/images/house-cards/Gregor.png";
import houndImage from "../../public/images/house-cards/Hound.png";
import jaimeImage from "../../public/images/house-cards/Jaime.png";
import kevanImage from "../../public/images/house-cards/Kevan.png";
import lorasImage from "../../public/images/house-cards/Loras.png";
import maceImage from "../../public/images/house-cards/Mace.png";
import margaeryImage from "../../public/images/house-cards/Margaery.png";
import melisandreImage from "../../public/images/house-cards/Melisandre.png";
import nymeriaImage from "../../public/images/house-cards/Nymeria.png";
import obaraImage from "../../public/images/house-cards/Obara.png";
import patchfaceImage from "../../public/images/house-cards/Patchface.png";
import queenImage from "../../public/images/house-cards/Queen.png";
import randyllImage from "../../public/images/house-cards/Randyll.png";
import redViperImage from "../../public/images/house-cards/RedViper.png";
import renlyImage from "../../public/images/house-cards/Renly.png";
import robbImage from "../../public/images/house-cards/Robb.png";
import rodrickImage from "../../public/images/house-cards/Rodrick.png";
import rooseImage from "../../public/images/house-cards/Roose.png";
import salladhorImage from "../../public/images/house-cards/Salladhor.png";
import stannisImage from "../../public/images/house-cards/Stannis.png";
import theonImage from "../../public/images/house-cards/Theon.png";
import tyrionImage from "../../public/images/house-cards/Tyrion.png";
import tywinImage from "../../public/images/house-cards/Tywin.png";
import victarionImage from "../../public/images/house-cards/Victarion.png";
import rayderImage from "../../public/images/house-cards/Rayder.png";
import melisandreDwDImage from "../../public/images/house-cards/Melisandre_DwD.png";
import jonSnow from "../../public/images/house-cards/JonSnow.png";
import stannisDwDImage from "../../public/images/house-cards/StannisBaratheonDwD.png";
import aeronDwDImage from "../../public/images/house-cards/AeronDamphairDwD.png";
import qarlTheMaidImage from "../../public/images/house-cards/QarlTheMaid.png";
import rodrikTheReaderImage from "../../public/images/house-cards/RodrikTheReader.png";
import euronDwDImage from "../../public/images/house-cards/EuronCrowsEye.png";
import qyburnImage from "../../public/images/house-cards/Qyburn.png";
import serAddamMarbrandImage from "../../public/images/house-cards/SerAddamMarbrand.png";
import serIlynPayneImage from "../../public/images/house-cards/SerIlynPayne.png";
import quentynMartellImage from "../../public/images/house-cards/QuentynMartell.png";
import serGerrisDrinkwaterImage from "../../public/images/house-cards/SerGerrisDrinkwater.png";
import doranMartellImage from "../../public/images/house-cards/DoranMartell.png";
import walderFreyImage from "../../public/images/house-cards/WalderFrey.png";
import reekImage from "../../public/images/house-cards/Reek.png";
import ramsayBoltonImage from "../../public/images/house-cards/RamsayBolton.png";
import queenOfThornsDwDImage from "../../public/images/house-cards/QueenOfThorns.png";
import paxterRedwyneImage from "../../public/images/house-cards/PaxterRedwyne.png";
import margaeryTyrellDwDImage from "../../public/images/house-cards/MargaeryTyrell.png";

import maceTyrellDwDImage from "../../public/images/house-cards/MaceTyrell.png";
import randyllTarlyDwDImage from "../../public/images/house-cards/RandyllTarly.png";
import willasTyrellImage from "../../public/images/house-cards/WillasTyrell.png";
import serJonFossowayImage from "../../public/images/house-cards/SerJonFossoway.png";

import nymeriaSandDwDImage from "../../public/images/house-cards/NymeriaSand.png";
import areoHotahDwDImage from "../../public/images/house-cards/AreoHotah.png";
import bastardOfGodsgraceImage from "../../public/images/house-cards/BastardOfGodsgrace.png";
import bigManImage from "../../public/images/house-cards/BigMan.png";

import serHarrasHarlawImage from "../../public/images/house-cards/SerHarrasHarlaw.png";
import victarionGreyjoyDwDImage from "../../public/images/house-cards/VictarionGreyjoy.png";
import ashaGreyjoyDwDImage from "../../public/images/house-cards/AshaGreyjoy.png";

import bastardOfNightsongImage from "../../public/images/house-cards/BastardOfNightsong.png";
import serDavosSeaworthDwDImage from "../../public/images/house-cards/SerDavosSeaworth.png";
import serAxellFlorentImage from "../../public/images/house-cards/SerAxellFlorent.png";

import blackWalderImage from "../../public/images/house-cards/BlackWalder.png";
import steelshanksWaltonImage from "../../public/images/house-cards/SteelshanksWalton.png";
import damonDanceForMeImage from "../../public/images/house-cards/DamonDanceForMe.png";
import rooseBoltonDwDImage from "../../public/images/house-cards/RooseBolton.png";

import cerseiLannisterDwDImage from "../../public/images/house-cards/CerseiLannister.png";
import serJaimeLannisterDwDImage from "../../public/images/house-cards/SerJaimeLannister.png";
import serKevanLannisterDwDImage from "../../public/images/house-cards/SerKevanLannister.png";
import serDavenLannisterImage from "../../public/images/house-cards/DavenLannister.png";

import varysImage from "../../public/images/house-cards/Varys.png";
import syrioImage from "../../public/images/house-cards/SyrioForel.png";
import jaqenImage from "../../public/images/house-cards/JaqenHGhar.png";
import janosImage from "../../public/images/house-cards/JanosSlynt.png";
import jonConningtonImage from "../../public/images/house-cards/JonConnington.png";
import bericImage from "../../public/images/house-cards/BericDondarrion.png";
import bronnImage from "../../public/images/house-cards/Bronn.png";

import alayneStoneFfcImage from "../../public/images/house-cards/AlayneStone-ffc.png"
import anyaWaynwoodModImage from "../../public/images/house-cards/AnyaWaynwood-mod.png"
import arstanWhitebeardAImage from "../../public/images/house-cards/ArstanWhitebeard-A.png"
import bronzeYohnRoyceFfcImage from "../../public/images/house-cards/BronzeYohnRoyce-ffc.png"
import bronzeYohnRoyceModImage from "../../public/images/house-cards/BronzeYohnRoyce-mod.png"
import captainGroleoBImage from "../../public/images/house-cards/CaptainGroleo-B.png"
import daarioNaharisBImage from "../../public/images/house-cards/DaarioNaharis-B.png"
import daenerysTargaryenAImage from "../../public/images/house-cards/DaenerysTargaryen-A.png"
import daenerysTargaryenBImage from "../../public/images/house-cards/DaenerysTargaryen-B.png"
import godricBorrellModImage from "../../public/images/house-cards/GodricBorrell-mod.png"
import grayWormBImage from "../../public/images/house-cards/GrayWorm-B.png"
import harryHardyngModImage from "../../public/images/house-cards/HarryHardyng-mod.png"
import illyrioMopatisAImage from "../../public/images/house-cards/IllyrioMopatis-A.png"
import khalDrogoAImage from "../../public/images/house-cards/KhalDrogo-A.png"
import littlefingerFfcImage from "../../public/images/house-cards/Littlefinger-ffc.png"
import lothorBruneFfcImage from "../../public/images/house-cards/LothorBrune-ffc.png"
import lynCorbrayFfcImage from "../../public/images/house-cards/LynCorbray-ffc.png"
import lysaArrynFfcImage from "../../public/images/house-cards/LysaArryn-ffc.png"
import lysaArrynModImage from "../../public/images/house-cards/LysaArryn-mod.png"
import missandeiBImage from "../../public/images/house-cards/Missandei-B.png"
import nestorRoyceFfcImage from "../../public/images/house-cards/NestorRoyce-ffc.png"
import rakharoBImage from "../../public/images/house-cards/Rakharo-B.png"
import robertArrynModImage from "../../public/images/house-cards/RobertArryn-mod.png"
import serJorahMormontAImage from "../../public/images/house-cards/SerJorahMormont-A.png"
import serVardisEgenModImage from "../../public/images/house-cards/SerVardisEgen-mod.png"
import strongBelwasBImage from "../../public/images/house-cards/StrongBelwas-B.png"
import viserysTargaryenAImage from "../../public/images/house-cards/ViserysTargaryen-A.png"
import xaroXhoanDaxosAImage from "../../public/images/house-cards/XaroXhoanDaxos-A.png"

const houseCardImages = new BetterMap([
    ["queen-of-thorns", queenImage],
    ["aeron-damphair", aeronImage],
    ["alester-florent", alesterImage],
    ["arianne-martell", arianneImage],
    ["areo-hotah", aeroImage],
    ["asha-greyjoy", ashaImage],
    ["balon-greyjoy", balonImage],
    ["the-blackfish", blackfishImage],
    ["brienne-of-tarth", brienneImage],
    ["catelyn-stark", catelynImage],
    ["cersei-lannister", cerseiImage],
    ["dagmar-cleftjaw", dagmarImage],
    ["darkstar", darkStarImage],
    ["ser-davos-seaworth", davosImage],
    ["doran-martell", doranImage],
    ["eddard-stark", eddardImage],
    ["euron-crows-eye", euronImage],
    ["ser-garlan-tyrell", garlanImage],
    ["greatjon-umber", greatjonImage],
    ["ser-gregor-clegane", gregorImage],
    ["the-hound", houndImage],
    ["ser-jaime-lannister", jaimeImage],
    ["ser-kevan-lannister", kevanImage],
    ["ser-loras-tyrell", lorasImage],
    ["mace-tyrell", maceImage],
    ["margaery-tyrell", margaeryImage],
    ["melisandre", melisandreImage],
    ["nymeria-sand", nymeriaImage],
    ["obara-sand", obaraImage],
    ["patchface", patchfaceImage],
    ["queen-of-thorns", queenImage],
    ["randyll-tarly", randyllImage],
    ["the-red-viper", redViperImage],
    ["renly-baratheon", renlyImage],
    ["robb-stark", robbImage],
    ["ser-rodrick-cassel", rodrickImage],
    ["roose-bolton", rooseImage],
    ["salladhor-saan", salladhorImage],
    ["stannis-baratheon", stannisImage],
    ["theon-greyjoy", theonImage],
    ["tyrion-lannister", tyrionImage],
    ["tywin-lannister", tywinImage],
    ["victarion-greyjoy", victarionImage],
    ["rayder", rayderImage],
    ["melisandre-dwd", melisandreDwDImage],
    ["jon-snow", jonSnow],
    ["stannis-baratheon-dwd", stannisDwDImage],
    ["aeron-damphair-dwd", aeronDwDImage],
    ["qarl-the-maid", qarlTheMaidImage],
    ["rodrik-the-reader", rodrikTheReaderImage],
    ["euron-crows-eye-dwd", euronDwDImage],
    ["qyburn", qyburnImage],
    ["ser-addam-marbrand", serAddamMarbrandImage],
    ["ser-ilyn-payne", serIlynPayneImage],
    ["quentyn-martell", quentynMartellImage],
    ["ser-gerris-drinkwater", serGerrisDrinkwaterImage],
    ["doran-martell-dwd", doranMartellImage],
    ["walder-frey", walderFreyImage],
    ["reek", reekImage],
    ["ramsay-bolton", ramsayBoltonImage],
    ["queen-of-thorns-dwd", queenOfThornsDwDImage],
    ["paxter-redwyne", paxterRedwyneImage],
    ["margaery-tyrell-dwd", margaeryTyrellDwDImage],
    ["mace-tyrell-dwd", maceTyrellDwDImage],
    ["randyll-tarly-dwd", randyllTarlyDwDImage],
    ["willas-tyrell", willasTyrellImage],
    ["ser-jon-fossoway", serJonFossowayImage],
    ["nymeria-sand-dwd", nymeriaSandDwDImage],
    ["areo-hotah-dwd", areoHotahDwDImage],
    ["bastard-of-godsgrace", bastardOfGodsgraceImage],
    ["big-man", bigManImage],
    ["ser-harras-harlaw", serHarrasHarlawImage],
    ["victarion-greyjoy-dwd", victarionGreyjoyDwDImage],
    ["asha-greyjoy-dwd", ashaGreyjoyDwDImage],
    ["bastard-of-nightsong", bastardOfNightsongImage],
    ["ser-davos-seaworth-dwd", serDavosSeaworthDwDImage],
    ["ser-axell-florent", serAxellFlorentImage],
    ["black-walder", blackWalderImage],
    ["steelshanks-walton", steelshanksWaltonImage],
    ["damon-dance-for-me", damonDanceForMeImage],
    ["roose-bolton-dwd", rooseBoltonDwDImage],
    ["cersei-lannister-dwd", cerseiLannisterDwDImage],
    ["ser-jaime-lannister-dwd", serJaimeLannisterDwDImage],
    ["ser-kevan-lannister-dwd", serKevanLannisterDwDImage],
    ["ser-daven-lannister", serDavenLannisterImage],
    ["varys", varysImage],
    ["syrio-forel", syrioImage],
    ["jaqen-h-ghar", jaqenImage],
    ["janos-slynt", janosImage],
    ["jon-connington", jonConningtonImage],
    ["beric-dondarrion", bericImage],
    ["bronn", bronnImage],
    ["alayne-stone", alayneStoneFfcImage],
    ["anya-waynwood", anyaWaynwoodModImage],
    ["arstan-whitebeard", arstanWhitebeardAImage],
    ["bronze-yohn-royce-ffc", bronzeYohnRoyceFfcImage],
    ["bronze-yohn-royce-mod", bronzeYohnRoyceModImage],
    ["captain-groleo", captainGroleoBImage],
    ["daario-naharis", daarioNaharisBImage],
    ["daenerys-targaryen-a", daenerysTargaryenAImage],
    ["daenerys-targaryen-b", daenerysTargaryenBImage],
    ["godric-borrell", godricBorrellModImage],
    ["gray-worm", grayWormBImage],
    ["harry-hardyng", harryHardyngModImage],
    ["illyrio-mopatis", illyrioMopatisAImage],
    ["khal-drogo", khalDrogoAImage],
    ["littlefinger", littlefingerFfcImage],
    ["lothor-brune", lothorBruneFfcImage],
    ["lyn-corbray", lynCorbrayFfcImage],
    ["lysa-arryn-ffc", lysaArrynFfcImage],
    ["lysa-arryn-mod", lysaArrynModImage],
    ["missandei", missandeiBImage],
    ["nestor-royce", nestorRoyceFfcImage],
    ["rakharo", rakharoBImage],
    ["robert-arryn", robertArrynModImage],
    ["ser-jorah-mormont", serJorahMormontAImage],
    ["ser-vardis-egen", serVardisEgenModImage],
    ["strong-belwas", strongBelwasBImage],
    ["viserys-targaryen", viserysTargaryenAImage],
    ["xaro-xhoan-daxos", xaroXhoanDaxosAImage]
]);

export default houseCardImages;
